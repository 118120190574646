import "./Home.css";
import Form from "../components/Form";
import TherapyIMG from "../images/psychotherapy.png";
import BrainIMG from "../images/brain.png";
import Portrait from "../images/portreit.png";
import Brusel from "../images/Brusel.png";

const Home = () => {
  return (
    <div>
      <section className="hero-image">
        <div className="hero-text">
          <h1>NENECHTE SI ŽIVOT UTÉCT</h1>
          <p>UKÁŽU VÁM PROČ</p>
        </div>
      </section>
      <section className="introduction">
        <h2> Co je to koučink?</h2>
        <h3>Mauris vitae nunc</h3>
        <div className="introduction-detail">
          <div>
            {" "}
            <p className="text">
              Je to účinná metoda, která pomůže provést klienta na jeho osobní
              nebo profesní cestě. Průvodcem a "parťákem" je kouč, který pokládá
              nezvyklé otázky a rozvíjí pohled klienta na danou situaci. Je
              potřeba aktivní spoluúčast koučovaného.
            </p>
            <ul className="text">
              <li>
                Cítíte se ve svém těle jako cizinec? Neustále se necítíte dobře
                ve své vlastní kůži?
              </li>
              <li>Cítíte se ochrnutí, když se probudíte?</li>
              <li>Nenávidíte se za to, že jste líní?</li>
              <li>Neustále kritizuješ sama sebe?</li>
            </ul>
          </div>
          <img src={TherapyIMG} alt="" className="image" />
        </div>
      </section>
      <section className="hero-image3"></section>
      <section className="introduction">
        <h2>Pro koho je osobní koučink?</h2>
        <h3>Donec mattis diam</h3>
        <div className="introduction-detail">
          <img src={BrainIMG} alt="" className="image" />
          <ul className="text">
            <li>
              Pro lidi, kteří mají problémy - s prací, zdravím, vztahy, penězi,
              se stresem a s trávením volného času.
            </li>
            <li>Pro lidi, co chtějí mít kontrolu nad svým životem.</li>
            <li>
              Pro lidi, co chtějí přes ovládnutí svých emocí a vnitřních stavů
              zlepšit svůj zdravotní stav.
            </li>
            <li>
              Pro lidi, co potřebují získat / zlepšit sebevědomí a odhalit svůj
              jedinečný talent.
            </li>
            <li>
              Pro lidi, co mají nakročeno k vyhoření, zažívají stereotyp a cítí
              potřebu změny, ale nevím jaké.
            </li>
          </ul>
        </div>
      </section>
      <section className="hero-image2"></section>
      <section className="introduction">
        <h2>Nemusí to tak být</h2>
        <h3>Donec mattis diam</h3>
        <div className="introduction-detail">
          <img src={Brusel} alt="" className="image" />
          <p>
            <p className="text-cursive">
              "Chci rodinu. Proč nemůžu najít někoho, kdo mě miluje?"
            </p>
            <p className="text-cursive">
              "Mám pocit, že můj život postrádá smysl"
            </p>
            <p className="text-cursive">
              "Je tu část mě samé/ho, kterou nenávidím."
            </p>
            <p className="text-cursive">
              "Cítím se sám/sama, i když jsem obklopen/a lidmi."
            </p>
            <p className="text-cursive">"Jsem zahlcen/a svou vlastní myslí."</p>
            <p></p>
          </p>
        </div>
      </section>
      <section className="contacts">
        <h2>Contact me</h2>
        <div className="contacts-detail">
          <img src={Portrait} alt="" className="portrait" />
          <div className="závěr">
            Potkat se můžeme osobně na dálku u mě , u Vás nebo kdekoliv Vám to
            bude příjemné
          </div>
          <div>
            <Form></Form>
          </div>
          <div className="závěr-závěru">
            JSEM TADY A BUDU S VÁMI RÁD SDÍLET VAŠE RADOSTI , STAROSTI , ŽIVOT,
            BUDU VÁS POSLOUCHAT A VNÍMAT….PROSTĚ PROTO , ŽE MĚ NA VÁS ZÁLEŽÍ
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
