import { MdAlternateEmail } from "react-icons/md";
import { FaPhone } from "react-icons/fa6";
import { RiInstagramFill } from "react-icons/ri";
import { FaFacebook } from "react-icons/fa";
import "./Footer.css";

const Footer = () => {
  return (
    <div>
      <footer className="footer">
        <p>
          <MdAlternateEmail></MdAlternateEmail>
          Email
        </p>
        <p>
          <FaPhone></FaPhone>
          Phone
        </p>
        <p>
          <RiInstagramFill></RiInstagramFill>
          Instagram
        </p>
        <p>
          <FaFacebook></FaFacebook>
          Facebook
        </p>
        <p>&copy;Matěj Šoffr, 2024</p>
      </footer>
    </div>
  );
};

export default Footer;
