import React, { useState } from "react";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Tady by proběhla logika pro odeslání, např. přes API
    console.log("Form data:", formData);
    alert("Vaše zpráva byla úspěšně odeslána!");
    setFormData({ name: "", email: "", message: "" });
  };

  return (
    <form onSubmit={handleSubmit} style={formStyle}>
      <label style={labelStyle}>
        Jméno:
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
          style={inputStyle}
        />
      </label>
      <label style={labelStyle}>
        E-mail:
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
          style={inputStyle}
        />
      </label>
      <label style={labelStyle}>
        Zpráva:
        <textarea
          name="message"
          value={formData.message}
          onChange={handleChange}
          required
          style={{ ...inputStyle, height: "100px" }}
        />
      </label>
      <button type="submit" style={buttonStyle}>
        Odeslat
      </button>
    </form>
  );
};

const formStyle = {
  maxWidth: "600px",
  margin: "auto",
  padding: "20px",
  borderRadius: "8px",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
  backgroundColor: "#f9f9f9",
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
};

const labelStyle = {
  fontWeight: "bold",
  display: "flex",
  flexDirection: "column",
  gap: "0.5rem",
};

const inputStyle = {
  padding: "8px",
  fontSize: "16px",
  borderRadius: "4px",
  border: "1px solid #ddd",
};

const buttonStyle = {
  padding: "10px 20px",
  fontSize: "16px",

  border: "none",
  borderRadius: "4px",
  cursor: "pointer",
};

export default ContactForm;
