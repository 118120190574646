import { NavLink } from "react-router-dom";
import "./Navbar.css";
const Navbar = () => {
  return (
    <header>
      <nav className="navbar">
        <h1>PETR KASAL</h1>
        <div className="navlink">
          <NavLink
            to="/"
            className={({ isActive }) =>
              isActive ? "activelink link" : "nonactivelink link"
            }
          >
            Home
          </NavLink>
          <NavLink
            to="/about"
            className={({ isActive }) =>
              isActive ? "activelink link" : "nonactivelink link"
            }
          >
            About
          </NavLink>{" "}
          <NavLink
            to="/contacts"
            className={({ isActive }) =>
              isActive ? "activelink link" : "nonactivelink link"
            }
          >
            Contacts
          </NavLink>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
